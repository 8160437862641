@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-ExtraLight.otf') format('opentype');
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-Light.otf') format('opentype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
