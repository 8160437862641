@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./clash-display.css');
@import url('./manrope.css');

.fire-link {
  @apply text-inherit hover:text-inherit visited:text-inherit;
}

/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none !important;
  }

  .no-scrollbar {
    -ms-overflow-style: none !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
  }
}
